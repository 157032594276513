import dayjs from "dayjs";
import ReportingPeriod from "@/enums/reportingPeriod";
import _ from "lodash";

import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
dayjs.extend(localizedFormat);
dayjs.extend(utc);

require("dayjs/locale/en-gb");
dayjs.locale("en-gb");

const SpecialDates = {
  Yesterday: "Yesterday",
  Today: "Today",
  Tomorrow: "Tomorrow"
};

const MinYear = 1910;

export default class datesFuncs {
  static sameMonth(date, month) {
    return new Date(date).getMonth() === month;
  }

  static shortFormat(date) {
    return date ? dayjs(date).format("L") : "";
  }

  static isToday(date) {
    return dayjs(date).startOf("day").toDate().getTime() === datesFuncs.today().getTime();
  }

  static isYesterday(date) {
    return dayjs(date).startOf("day").add(1, "day").toDate().getTime() === datesFuncs.today().getTime();
  }

  static isTomorrow(date) {
    return dayjs(date).startOf("day").add(-1, "day").toDate().getTime() === datesFuncs.today().getTime();
  }

  static isFutureDate(date) {
    if (!date) return true; // null date implies its is in future
    return dayjs(date).toDate().getTime() >= datesFuncs.today().getTime();
  }

  // Always show date and time
  static formatDateTime(date) {
    return datesFuncs.shortFormatDate(date) + " " + datesFuncs.shortFormatTime(date);
  }

  // Always show date, show time only for yesterday, today, tomorrow
  static formatDateTimeRecent(date) {
    let result = datesFuncs.shortFormatDate(date);
    if (!result) return result;
    // show time only if special date: today/tomorrow/yesterday
    const special = !!SpecialDates[result];
    var thisDate = dayjs(date);
    if (special && thisDate.hour() !== 0 && thisDate.minute() !== 0) {
      result += " " + thisDate.format("LT");
    }
    return result;
  }

  // Hide date for today, show time if not zero
  static shortFormatDateTime(date) {
    var thisDate = dayjs(date);
    let result = datesFuncs.shortFormatDate(date);
    // hide day if today
    const today = result === SpecialDates.Today;
    // add time only if not zero, (unless today)
    if (thisDate.hour() !== 0 || thisDate.minute() !== 0 || today) {
      result += " " + thisDate.format("LT");
    }
    return result;
  }

  static shortFormatTime(date) {
    return dayjs(date).format("LT");
  }

  // Date Only
  static shortFormatDate(date) {
    if (datesFuncs.isNullDate(date)) return "";
    var thisDate = dayjs(date);
    // prettier-ignore
    return datesFuncs.isToday(date)
      ? SpecialDates.Today
      : datesFuncs.isYesterday(date)
        ? SpecialDates.Yesterday
        : datesFuncs.isTomorrow(date)
          ? SpecialDates.Tomorrow
          : thisDate.format("L");
  }

  static weekDay(day) {
    return dayjs("2021-05-30").add(day, "day").format("dddd");
  }

  static dateMonthYear(date) {
    return dayjs(date).format("MMM YY");
  }

  static dateMonth(date) {
    return dayjs(date).format("MMM");
  }

  static dateYear(date) {
    return dayjs(date).format("YYYY");
  }

  static formatYYYYMMDD(date) {
    if (datesFuncs.isNullDate(date)) return null;
    return dayjs(date).format("YYYY-MM-DD");
  }

  static monthName(month) {
    return dayjs().month(month).format("MMM");
  }

  static currentMonth() {
    return dayjs().month();
  }

  static lastMonth() {
    let month = dayjs().month() - 1;
    if (month < 0) month = 11;
    return month;
  }

  static last6MonthNums(month) {
    // last six months
    var monthNums = [];
    // if (this.month >= 5) {
    monthNums = _.range(month - 5, month + 1);
    // } else {
    //   monthNums = _.range(0, this.month + 1).concat(_.range(0, this.month + 1));
    // }
    return monthNums;
  }

  static last6MonthNames(month) {
    return datesFuncs.last6MonthNums(month).map(m => datesFuncs.monthName(m));
  }

  static getDateOfISOWeek(w, y, d) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    // above sets start as Monday, we offest based on day of week we want
    return datesFuncs.addDays(ISOweekStart, d - 1);
  }

  static addHours(thisDate, hours) {
    return dayjs(thisDate).add(hours, "hour").toDate();
  }

  static addDays(thisDate, days) {
    return dayjs(thisDate).add(days, "day").toDate();
  }

  static addMonths(thisDate, months) {
    return dayjs(thisDate).add(months, "month").toDate();
  }

  static dateOrNull(thisDate) {
    let date = thisDate ? new Date(thisDate) : null;
    if (datesFuncs.isNullDate(date)) date = null;
    return date;
  }

  static today() {
    return dayjs().startOf("day").toDate();
  }

  static now() {
    return dayjs().toDate();
  }

  static startOfMonth(date) {
    return new Date(date.setDate(1));
  }

  static endOfMonth(date) {
    // day of zero, means 1 day back
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  static startOfQuarter(date) {
    return new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1);
  }

  static endOfQuarter(date) {
    return datesFuncs.endOfMonth(datesFuncs.addMonths(datesFuncs.startOfQuarter(date), 2));
  }

  static startOfSixMonths(date) {
    return new Date(date.getFullYear(), Math.floor(date.getMonth() / 6) * 6, 1);
  }

  static endOfSixMonths(date) {
    return datesFuncs.endOfMonth(datesFuncs.addMonths(datesFuncs.startOfSixMonths(date), 5));
  }

  static startOfYear(date) {
    return new Date(date.getFullYear(), 0, 1);
  }

  static endOfYear(date) {
    return new Date(date.getFullYear(), 11, 31);
  }

  static startOfPeriod(date, reportingPeriod) {
    switch (reportingPeriod) {
      case ReportingPeriod.Enum.Month:
        return datesFuncs.startOfMonth(date);
      case ReportingPeriod.Enum.Quarter:
        return datesFuncs.startOfQuarter(date);
      case ReportingPeriod.Enum.SixMonths:
        return datesFuncs.startOfSixMonths(date);
      case ReportingPeriod.Enum.Year:
        return datesFuncs.startOfYear(date);
      default:
        return date;
    }
  }

  static periodFormat(date, period) {
    switch (period) {
      case ReportingPeriod.Enum.Month:
        return datesFuncs.dateMonthYear(date);
      case ReportingPeriod.Enum.Quarter:
        return datesFuncs.dateMonth(date) + "-" + datesFuncs.dateMonthYear(datesFuncs.addMonths(date, 2));
      case ReportingPeriod.Enum.SixMonths:
        return datesFuncs.dateMonth(date) + "-" + datesFuncs.dateMonthYear(datesFuncs.addMonths(date, 5));
      case ReportingPeriod.Enum.Year:
        return datesFuncs.dateYear(date);
      case ReportingPeriod.Enum.Day:
      case ReportingPeriod.Enum.WeekStarting:
      case ReportingPeriod.Enum.WeekEnding:
      default:
        return datesFuncs.shortFormat(date);
    }
  }

  // Handle nulls and also DateMin
  static isNullDate(thisDate) {
    const date = thisDate ? new Date(thisDate) : null;
    return !date || date.getFullYear() < MinYear;
  }

  static MinDate() {
    return new Date(MinYear, 0, 1);
  }

  static ifNullDate(date, dateIfNull) {
    return datesFuncs.isNullDate(date) ? dateIfNull : date;
  }

  static previousPeriods(reportingPeriod, numPeriods) {
    let startDate = datesFuncs.startOfPeriod(datesFuncs.today(), reportingPeriod);
    const dates = [startDate.getTime()];
    for (let i = 0; i < numPeriods; i++) {
      if (reportingPeriod >= ReportingPeriod.UseMonthsFrom) {
        startDate = datesFuncs.addMonths(startDate, -ReportingPeriod.NumDaysMonths[reportingPeriod]);
      } else {
        startDate = datesFuncs.addDays(startDate, -ReportingPeriod.NumDaysMonths[reportingPeriod]);
      }
      dates.push(startDate.getTime());
    }
    return dates;
  }

  static previousPeriod(startDate, reportingPeriod, numPeriods) {
    let date = startDate ?? datesFuncs.today();
    for (let i = 0; i < numPeriods; i++) {
      if (reportingPeriod >= ReportingPeriod.UseMonthsFrom) {
        date = datesFuncs.addMonths(date, -ReportingPeriod.NumDaysMonths[reportingPeriod]);
      } else {
        date = datesFuncs.addDays(date, -ReportingPeriod.NumDaysMonths[reportingPeriod]);
      }
    }
    return date;
  }

  static sameDateValue(date1, date2) {
    return date1?.getTime() === date2?.getTime();
  }

  static validDate(date) {
    return date && date > new Date(1970, 0, 1) && date < new Date(2070, 0, 1);
  }

  static safeParseDate(current, value) {
    let newDate = dayjs(value).toDate();
    if (!value || !datesFuncs.validDate(newDate)) newDate = current;
    return newDate;
  }

  static toUtc(date) {
    return dayjs(date).utc(true).toDate();
  }

  static calcAge(dob, current) {
    return dayjs(current).diff(dob, "years");
  }

  static daysSince(date, current) {
    return datesFuncs.isNullDate(date) ? 0 : dayjs(current).diff(date, "days");
  }
}
