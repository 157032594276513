export default {
  SelectLookups: [
    [
      { id: 1, value: "Ulcerated" },
      { id: 2, value: "Bleeding" },
      { id: 3, value: "Dray / Cracked" },
      { id: 4, value: "Pink / Smooth/ Intact" }
    ],
    [
      { id: 1, value: "Ill fitting dentures / Caries / Edentulous" },
      { id: 2, value: "Generalised Plaque / Debris" },
      { id: 3, value: "Plague / localised debris" },
      { id: 4, value: "Clean & free from debris" },
      { id: 0, value: "No Teeth" }
    ],
    [
      { id: 1, value: "Ulcerated / Bleeding" },
      { id: 2, value: "Tender / Spongy" },
      { id: 3, value: "Red, Oedematous" },
      { id: 4, value: "Pink, Moist, Firm" }
    ],
    [
      { id: 1, value: "Ulcerated / Cracked / Dry" },
      { id: 2, value: "Shiny Red" },
      { id: 3, value: "Coated" },
      { id: 4, value: "Pink / Moist/ Papillae" }
    ],
    [
      { id: 1, value: "Ulcerated / Bleeding" },
      { id: 2, value: "White Area" },
      { id: 3, value: "Red / Coated" },
      { id: 4, value: "Pink / Moist / Smooth" }
    ],
    [
      { id: 1, value: "Absent" },
      { id: 2, value: "Insufficent" },
      { id: 3, value: "Thick" },
      { id: 4, value: "Present / Watery" }
    ],
    [
      { id: 1, value: "Open Mouth Breathing" },
      { id: 2, value: "Nebuliser Therapy" },
      { id: 3, value: "Humified Oxygen" },
      { id: 4, value: "Normal" }
    ],
    [
      { id: 1, value: "Immobile" },
      { id: 2, value: "Very Limited" },
      { id: 3, value: "Slightly Limited" },
      { id: 4, value: "Full" }
    ],
    [
      { id: 1, value: "No Oral Intake" },
      { id: 2, value: "Fluids Only" },
      { id: 3, value: "Inadequate Diet" },
      { id: 4, value: "Good" }
    ],
    [
      { id: 1, value: "Dependent" },
      { id: 2, value: "Needs much help" },
      { id: 3, value: "Needs some help" },
      { id: 4, value: "Independent" }
    ],
    [
      { id: 1, value: "Un-cooperative / Unconscious / Confused" },
      { id: 2, value: "Sedated" },
      { id: 3, value: "Apathetic" },
      { id: 4, value: "Alert" }
    ],
    [
      { id: 1, value: "Uncontrolled Pain" },
      { id: 2, value: "Intermittent Pain" },
      { id: 3, value: "Fear of Pain" },
      { id: 4, value: "Pain Free" }
    ]
  ]
};
