<template>
  <div class="card">
    <header v-if="title" class="card-header">
      <p class="card-header-title">
        <b-icon v-if="icon" :icon="Icons[icon] || icon" custom-size="default" />
        <span v-if="customIcon" class="icon has-text-primary is-small mr-2">
          <img :src="'/img/icons/' + customIcon + '_invert.png'" />
        </span>
        {{ title }}
      </p>
      <a v-if="headerIcon" href="#" class="card-header-icon" aria-label="more options" @click.prevent="headerIconClick">
        <b-icon :icon="headerIcon" />
      </a>
      <ul v-if="headerIcons" class="is-flex">
        <li v-for="icon in headerIcons" :key="icon.name">
          <a href="#" class="card-header-icon" @click.prevent="headersIconClick(icon.name)">
            <b-tooltip :label="icon.title" type="is-primary is-light">
              <b-icon :icon="icon.icon" />
            </b-tooltip>
          </a>
        </li>
      </ul>
    </header>
    <div class="card-content" :class="{ fullHeight: fullHeight }">
      <slot />
    </div>
  </div>
</template>

<script>
import IconsMixin from "@/mixins/iconsMixin";

export default {
  mixins: [IconsMixin],
  name: "CardComponent",
  props: {
    title: { type: String, default: null },
    icon: { type: String, default: null },
    customIcon: { type: String, default: null },
    headerIcon: { type: String, default: null },
    headerIcons: { type: Array },
    fullHeight: { type: Boolean }
  },
  methods: {
    headerIconClick() {
      this.$emit("header-icon-click");
    },
    headersIconClick(iconName) {
      this.$emit("header-icons-click", iconName);
    }
  }
};
</script>

<style>
.fullHeight {
  max-height: calc(100vh - 147px);
  overflow-y: scroll;
  padding: 0.8em;
}
</style>
