// Call setNotDirty() on data loaded from server

export default {
  data() {
    return {
      initData: {}
    };
  },
  methods: {
    // Define what data to check, override as required
    getFormData() {
      return this.form;
    },
    // call this when data set
    setNotDirty(afterSave) {
      // console.log("setNotDirty", this._uid, JSON.stringify(this.getFormData()));
      // if after save, set it immediately
      if (afterSave) this.initData = JSON.stringify(this.getFormData());
      else {
        // otherwise use nextTick so any dependencies are updated
        this.$nextTick(() => {
          this.initData = JSON.stringify(this.getFormData());
          // console.log("setNotDirty nextTick", this._uid, JSON.stringify(this.getFormData()));
        });
      }
    },
    setDirty() {
      this.initData = null;
    },
    checkWindowUnload(event) {
      if (this.isDirty()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    isDirty() {
      // console.log(
      //   "\nisDirty",
      //   this._uid,
      //   JSON.stringify(this.getFormData()) !== this.initData,
      //   JSON.stringify(this.getFormData()),
      //   "------------------------------------\n",
      //   this.initData
      // );
      return JSON.stringify(this.getFormData()) !== this.initData;
    },
    checkIsDirty(to, from, next) {
      if (this.isDirty()) {
        this.$buefy.dialog.confirm({
          title: "Your changes will be lost",
          type: "is-warning",
          hasIcon: true,
          icon: "alert",
          message: "Are you sure yo wish to close this form and discard your changes?",
          cancelText: "Stay on Page",
          confirmText: "Discard Changes",
          onConfirm: () => next()
        });
      } else {
        if (next) next();
      }
    },
    dataSaved() {
      // Data Saved, so clear dirty flag
      this.setNotDirty(true);
    },
    // default mapServerData when no special fields such as dates
    mapServerData(data) {
      this.form = data; // map top level params
      this.setNotDirty();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.checkWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.checkWindowUnload);
  },
  // Must be route level component
  beforeRouteLeave: function (to, from, next) {
    if (from.meta.dirtyHandledInSubcomponent) next();
    else this.checkIsDirty(to, from, next);
  },
  mounted() {
    this.setNotDirty();
  }
};
