<template>
  <div class="loading-overlay is-active">
    <p class="loadingText">Fetching data...</p>
    <b-loading :is-full-page="false" v-model="isLoading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true
    };
  }
};
</script>
