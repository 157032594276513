import Dates from "@/common/dates";
import { mapGetters } from "vuex";
import CheckDirty from "@/mixins/checkDirty";
import PermissionsEnum from "@/enums/permissions";

// Methods common to all Assessment forms
// Handles mapping form data to/from the common AssessmentForm
export default {
  mixins: [CheckDirty],
  props: {
    id: {
      // comes from route, cast to number
      type: [Number],
      default: 0
    }
  },
  watch: {
    id(newValue) {
      if (!newValue) {
        this.form = this.getClearFormObject();
      } else {
        this.form.assessmentId = newValue; // trigger refresh of data from server
      }
    }
  },
  computed: {
    ...mapGetters("resident", ["residentId", "residentDob"]),
    readonly() {
      return !!this.form.assessmentId && !this.$store.getters.hasPermission(PermissionsEnum.AssessmentUpdate);
    },
    saveDisabled() {
      return this.readonly || !this.isDirty();
    }
  },
  methods: {
    setResidentData(data) {
      // copy values from resident
      this.form.residentId = data.residentId;
      this.form.age = data.age;
      this.form.gender = data.gender;
    },
    setAssessmentDate(v) {
      this.form.assessmentDate = v;
      this.form.age = Dates.calcAge(this.residentDob, v);
    },
    mapServerData(data) {
      // basic mapServerData function - create new one in component if more functionality needed
      this.form = data; // map top level params
      this.form.assessmentDate = new Date(data.assessmentDate);

      this.setNotDirty();
    }
  }
};
