<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :score="score"
    :rating="rating"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <template v-for="(item, i) in form.items">
      <b-field :key="item.lookupId" :label="lookupName(item.lookupId)" horizontal class="label-long">
        <enum-drop-down v-model="item.score" :data="selectLookups[i]" mediumWidth :disabled="readonly" />
      </b-field>
    </template>
    <card-component>
      <b-field horizontal class="label-long">
        <b-checkbox v-model="form.antibiotics" :disabled="readonly">
          Large doses of antibiotics / steriod therapy /anti-depressants / antihistamines
        </b-checkbox>
      </b-field>
      <b-field horizontal class="label-long">
        <b-checkbox v-model="form.radiotherapy" :disabled="readonly">
          Radiotherapy of the head / neck
        </b-checkbox>
      </b-field>
      <b-field horizontal class="label-long">
        <b-checkbox v-model="form.diabetes" :disabled="readonly">
          Diabetes mellitus
        </b-checkbox>
      </b-field>
      <b-field horizontal class="label-long">
        <b-checkbox v-model="form.anaemia" :disabled="readonly">
          Anaemia
        </b-checkbox>
      </b-field>
    </card-component>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import EnumDropDown from "@/components/EnumDropDown";
import CardComponent from "@/components/CardComponent";
import _ from "lodash";

import AssessmentType from "@/enums/assessmentType";
import assessmentMixin from "@/mixins/assessmentMixin";

import lookups from "@/enums/lookups.js";
import ServerRisk from "@/enums/severe.js";
import OralCavity from "@/enums/oralCavity.js";

const ItemLookUpStart = 278;
const ItemLookUpNumValues = 12;

export default {
  components: { AssessmentForm, EnumDropDown, CardComponent },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.OralCavity,
      lookupType: lookups.Enum.OralCavity,
      selectLookups: OralCavity.SelectLookups,
      form: this.getClearFormObject()
    };
  },

  computed: {
    subItems() {
      var values = this.$store.getters["lookups/getLookups"]({ lookupType: this.lookupType });
      if ((!values || !values.length) && !this.fetchingData) {
        this.getData();
      }
      return values;
    },

    score() {
      let score = this.form.items.reduce((t, i) => t + parseInt(i?.score ?? 0), 0);
      if (this.form.antibiotics) score -= 2;
      if (this.form.radiotherapy) score -= 2;
      if (this.form.diabetes) score -= 2;
      if (this.form.anaemia) score -= 2;
      if (score < 0) score = 0;
      return score;
    },
    rating() {
      if (this.score >= 48) return ServerRisk.Enum.Normal;
      if (this.score >= 36) return ServerRisk.Enum.Mild;
      if (this.score >= 24) return ServerRisk.Enum.Moderate;
      return ServerRisk.Enum.Severe;
    }
  },
  watch: {
    subItems: "initSubItems"
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      await this.$store.dispatch("lookups/getLookups", { lookupType: this.lookupType });
      this.fetchingData = false;
    },
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: this.residentId,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        items: this.getClearFormItems(),
        antibiotics: false,
        radiotherapy: false,
        diabetes: false,
        anaemia: false
      };
    },
    initSubItems() {
      if (!this.form.items.length) this.form.items = this.getClearFormItems();
    },
    getClearFormItems() {
      return _.range(0 + ItemLookUpStart, ItemLookUpNumValues + ItemLookUpStart).map(r => {
        return { lookupId: r };
      });
    },
    lookupName(id) {
      return this.subItems.find(i => i.lookupId === id)?.name ?? "";
    }
  }
};
</script>
